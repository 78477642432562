import { Box, Typography } from "@mui/material"
import Carousel from "react-material-ui-carousel"

const content = [`We welcome you to join us in our noble journey to get connected with Allāh. Let's return to Him while we are still alive. Our primary aim at the Markaz is to help people especially sisters develop a connection with Allāh in a manner that is according to their comfort and pace.`,
    `Markaz Al Midād was established in 2023 by Ustādah Ummqaariah with the primary goal of aiding people in having the knowledge of primary and secondary texts both in a traditional and academic way.`,
    `Ustādah has done her Aalimiyyah, Masters in Islamic Studies and pursuing Masters in Psychology. Besides that she has teaching experience of more than 10 years.`,
    `There are students enrolled in different courses from all over the world; Kashmir, India, Pakistan, UK, Qatar, UAE etc.`,
    `Curriculum : Hifdh, Tajweed, Tafseer, Aqeedah, Fiqh, Seerah, kids Tarbiyyah programme and many more subjects.`]

const DescriptionTop = () => {
    return <Box px={2} my={5}>
        <Carousel animation="slide" duration={700} indicators={false} sx={{ marginBottom: 3 }}>
            {content.map((line, index) => <Typography key={index}>{line}</Typography>)}
        </Carousel>
        <Typography textAlign="center">May Allāh accept it from us!</Typography>
    </Box>
}


export default DescriptionTop