import { Box, IconButton, Typography } from "@mui/material"
import emailLogo from "../assets/sm-icons/email-logo.png"
import whatsappLogo from "../assets/sm-icons/whatsapp-logo.png"
import instagramLogo from "../assets/sm-icons/instagram-logo.png"

const ConnectWithUs = () => {
    return <>
        <Typography variant="h3" textAlign="center" mt={5} mb={3} fontStyle={"italic"}>Connect With Us</Typography>
        <Box display="flex" justifyContent={"space-evenly"} mb={8}>
            <IconButton LinkComponent={'a'} href="https://wa.me/918825075174?text=Assalamualaikum, I found this contact from Markaz-Al-Midad Website" target="blank">
                <img src={whatsappLogo} height={50} alt="" />
            </IconButton>
            <IconButton LinkComponent={'a'} href="mailto:umm.qaariah@markazalmidad.com?subject=Assistance with a Course&body=Assalamualaikum, I found this email from Markaz-Al-Midad Website" target="blank">
                <img src={emailLogo} height={50} alt="" />
            </IconButton>
            <IconButton LinkComponent={'a'} href="https://www.instagram.com/markaz.almidad/" target="blank">
                <img src={instagramLogo} height={50} alt="" />
            </IconButton>
        </Box>
    </>
}

export default ConnectWithUs