import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Tooltip from '@mui/material/Tooltip';

import Logo from "../assets/markaz_logo-horizontal.png"

function NavBar() {
    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters >
                    <Box sx={{ flexGrow: 1, mx: 1, py: 1 }} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <img src={Logo} alt="Markaz al Midad Logo" height={50} />
                        <Tooltip title="Whatsapp">
                            <IconButton LinkComponent={'a'} href='https://wa.me/918825075174' target='blank' sx={{ p: 0 }}>
                                <WhatsAppIcon style={{ fontSize: "45px", color: "#36BB22" }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default NavBar;
