import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';

import { Box, IconButton, Typography } from '@mui/material';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return <Box py={2} display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent={{ xs: "center", md: "space-evenly" }} alignItems={{ xs: "center", md: "space-evenly" }} backgroundColor="primary.main" width="100%">
        <Box display="flex" justifyContent={"space-around"} maxWidth="300px" width="100%" >
            <IconButton LinkComponent={'a'} href="https://wa.me/918825075174?text=Assalamualaikum, I found this contact from Markaz-Al-Midad Website" target="blank">
                <WhatsAppIcon />
            </IconButton>
            <IconButton LinkComponent={'a'} href="mailto:umm.qaariah@markazalmidad.com?subject=Assistance with a Course&body=Assalamualaikum, I found this email from Markaz-Al-Midad Website" target="blank">
                <EmailOutlinedIcon />
            </IconButton>
            <IconButton LinkComponent={'a'} href="https://www.instagram.com/markaz.almidad/" target="blank">
                <InstagramIcon />
            </IconButton>
        </Box>
        <Box display="flex" justifyContent={"center"} my={2}>
            <CopyrightOutlinedIcon style={{ fontSize: "17px", marginRight: 6 }} />
            <Typography variant="subtitle2" textAlign={"center"}>{" "} {currentYear} Copyright Markaz-Al-Midad </Typography>
        </Box>
    </Box>
}


export default Footer