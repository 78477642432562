import { Box, Button, Typography } from "@mui/material"

const UserAppRdr = () => {
    return <>
        <Box marginTop={5} />
        <Typography variant="h4" textAlign="center" fontStyle={"italic"}>Already Registered?</Typography>
        <Box marginTop={1} display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent={"center"} >
            <Button variant="outlined" color="secondary" style={{ margin: "0px 3px" }}
                LinkComponent={'a'} href="/releases/markazalmidad-release-v0.4.apk" target="_blank"
            >Use our Android App</Button>
            <Button variant="outlined" color="secondary" style={{ margin: "0px 3px" }} href="https://app.markazalmidad.com/login" target="_blank">Or Continue in your Browser </Button>
        </Box>
        <Box marginBottom={4} />
    </>
}



export default UserAppRdr