import { Container } from "@mui/material"

import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';

import CarouselTop from "./CarouselTop"
import CoursePathways from "./CoursePathways"
import Footer from "./Footer"
import NavBar from "./NavBar"
import ConnectWithUs from "./ConnectWithUs"
import DescriptionTop from "./DescriptionTop";
import UserAppRdr from "./UserAppRdr";

const themeOptions = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#eeefee',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const Index = () => {
    const theme = responsiveFontSizes(themeOptions)
    return <>
        <ThemeProvider theme={theme}>
            <NavBar />
            <Container maxwidth="xl" disableGutters>
                <CarouselTop />
                <DescriptionTop />
                <CoursePathways />
                <ConnectWithUs />
                <UserAppRdr />
            </Container>
            <Footer />
        </ThemeProvider>
    </>
}

export default Index