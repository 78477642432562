import { useState } from "react"

import { Box, Container, Grid, Dialog, Typography } from "@mui/material"
import { WhatsApp } from "@mui/icons-material"

import img1 from "../assets/courses-imgs/167aa888-39a0-4591-a27a-a029087f12cd.jpeg"
import img2 from "../assets/courses-imgs/d4375292-eabe-485c-82da-648dff36a621.jpeg"
import img3 from "../assets/courses-imgs/d83380f5-6172-4107-b192-2133da3c1c45.jpeg"
import img4 from "../assets/courses-imgs/2d2554c2-69bf-4a08-abef-a8bba67f4445.jpeg"
import img5 from "../assets/courses-imgs/695b4d26-950b-414c-8474-cb80b6048aae.jpeg"
import img6 from "../assets/courses-imgs/bustan (5).jpeg"
import img7 from "../assets/courses-imgs/cb235cfe-70d7-46ce-b182-75ca6d6a3bcb.jpeg"
import img8 from "../assets/courses-imgs/seerah.jpeg"

const pathways = [
    {
        title: "Pre-Ālimiyyah Programme",
        subtitle: "Self paced online learning",
        img: img1,
        data: `"Pre-Ālimiyyah" programme is a unique programme specially designed for students, working classes/professionals and those who can't attend the live classes. It includes the following curriculum:
       Tafseer
       Fiqh of Purification, Salah, sawm etc
       Aqeedah
       Specific Asmaul Husna
       Advanced Tajweed
        Including a free Tarbiyyah course`.split("\n")
    },
    {
        title: "Tafseer",
        subtitle: "Detailed interpretation along with tadabbur of Qur'an",
        img: img2,
        data: `"Tafseer" includes the following features:

        • Word to word translation of verses of Qur'ān
        • Holistic Translation
        • Deep and detailed interpretation of each word in the verse 
        • Grammatical analysis
        • Background/Sabab Al-Nazūl of each verse
        • Tadabbur by relating Qur'ān with our lives
        
        
        Chapters/Surahs included:
        • Juz Amma complete
        • Surah Al-Nur
        • Surah Al-Kahf`.split("\n")
    },
    {
        title: "Jurisprudence / Fiqh",
        subtitle: "Things every Muslim should know",
        img: img3,
        data: `"Jurisprudence/Fiqh" includes the courses that deal with rulings pertaining to the basic knowledge about purification, Salah, Sawm and many other issues.
        It comprises of analyzing the opinions of various scholars of 4 madhāhib and the verdict of the Jumhoor then in the said issues.
        It has been designed in a very smooth and simple way for the understanding of all the students - from laymen to educated.`.split("\n")
    },
    {
        title: "Theology / Aqeedah",
        subtitle: "Basics which differentiate Muslims from others",
        img: img4,
        data: `"Aqqedah/theology" is the course that includes the core beliefs a Muslim needs to believe in.
        It involves mainly the 3 kinds of Tawhīd along with the textual proofs.
        It gradually goes from simple to complex levels including some of the names of Allāh for better understanding of the Tawhīd. `.split("\n")

    },
    {
        title: "Tajweed",
        subtitle: "Learn to read the Qur'an as it was revealed",
        img: img5,
        data: `"Tajweed" is an advanced course that involves the articulation of Huroof al Hijaa to the level of Mudood in a very comprehensive and thorough manner.
        It includes some of the unfamiliar yet important rules of Tajweed and thus makes one in proficient in reading the Qur'ān exactly as narrated from Prophet صلی الله علیه وسلم`.split("\n")
    },
    {
        title: "Kids Tarbiyyah",
        img: img6,
        data: `"Kids Tarbiyyah" is our specially designed programme for kids from age 5 to 19 years old. 

        This programme includes;
        
        • Teaching Kids how to read Qur'ān from Basics/Tajweed
        • Aqeedah
        • Daily supplications
        • Prophetic narrations/Ahadīth
        • Stories of Prophets`.split("\n")
    },
    {
        title: "Seerah Al Nabawiyyah",
        img: img8,
        data: `"Seerah Al Nabawiyyah" is a unique course that discusses the life of Prophet صلی الله علیه وسلم in an academic manner which helps to answer the queries of those who try to defame him aka his opponents.
        It starts from the Arab world before the prophet's birth to the time period of his demise.
        It deals with each event in such a way that makes it as if you're living it and experiencing it in your real life.
        It helps students to know the life and manners of Prophet صلی الله علیه وسلم in a way that embodies love in our hearts for him and by relating each event with our lives.`.split("\n")
    },
    {
        title: "Journey of the Seeker",
        subtitle: "Discussing the contemporary issues in relevance with Qur'an and Sunnah",
        img: img7,
        data: `"Journey of the seeker" is our unique programme where contemporary and delicate matters are being explained under the guidance of Qur'ān and Sunnah along with the academic approach for being ready with skillful answers to the queries of people (Muslim/Non Muslim). 

        Till now, the courses under this programme include;
         
        • Why are there different sects in Islam? 
        • Difference between Shi'ah and Sunni
        • The veil/jilbab of Muslim women; why and how? 
        • How to plan your year
        • Depression and Iman
        • Reality of Jinn
        • How to cure yourself from Jinn possession, sihr and Ayn
        • Do's and Don't's of Salatul Istikharah`.split("\n")
    }
]

const CoursePathways = () => {
    return (
        <>
            <Box marginTop={5} />
            <Typography variant="h3" textAlign="center" fontStyle={"italic"}>Course Pathways</Typography>
            <Box marginTop={2} />
            <Grid container display="flex" flexDirection={"row"} justifyContent={"center"} alignItems="stretch" paddingX={3} marginBottom={12}>
                {pathways.map((pathway, index) => <CoursePathway key={index} {...{ pathway }} />)}
            </Grid>
        </>
    )
}

const CoursePathway = ({ pathway }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (<>
        <Grid item xs={10} sm={6} md={4} lg={3} >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight={150} boxShadow={2} margin={1} padding={1} borderRadius={2}
                onClick={handleOpen}
            >
                {pathway.img ? <img src={pathway.img} width="100%" alt="" /> : <></>}
                <Typography variant="h4" textAlign="center" mt={2}>{pathway.title}</Typography>
                {pathway.subtitle ?
                    <Typography variant="body1" textAlign="center">{pathway.subtitle}</Typography>
                    : <></>
                }
            </Box>
        </Grid>
        <CoursePathwayPopup handleClose={handleClose} open={open} pathway={pathway} />
    </>
    )
}

function CoursePathwayPopup({ handleClose, open, pathway }) {
    return (
        <Dialog onClose={handleClose} open={open}>
            <Container maxWidth="sm">
                <Box
                    m={1}
                    p={2}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                >
                    {/* NAME & DESIGNATION */}
                    <Box display="flex" flexDirection="column" justifyContent={"center"}>
                        <Typography variant="h5" gutterBottom>{pathway.title}</Typography>
                        <Typography variant="body1" gutterBottom>BārakAllāhu feekum!</Typography>
                        {pathway.data && pathway.data.map((line, index) => <Typography key={index} variant="body1" style={{ color: "#777", marginBottom: 1 }}>
                            {line}
                        </Typography>)}
                    </Box>
                </Box>
                <Box
                    my={3}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                >
                    <a
                        href={`https://wa.me/918825075174?text=Assalamualaikum, I would like to know about the course "${pathway.title}"`}
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <WhatsApp />
                    </a>
                </Box>
            </Container>
        </Dialog>
    );
}



export default CoursePathways