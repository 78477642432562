import imageTop from "../assets/A Journey Towards Allah.png"


const CarouselTop = () => {
    const items = [
        {
            image: imageTop,
        },
    ]
    return items.map((item, i) => <Item key={i} item={item} />)
}

function Item({ item }) {
    return (
        <img src={item.image} alt="" style={{ width: "100%" }} />
    )
}


export default CarouselTop